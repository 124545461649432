<template>
  <b-card no-body class="card-statistics" v-if="loading">
    <b-card-header>
      <b-card-title></b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- ข้อมูลเดือน :: กันยายน 2565 -->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in _statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-4"
          :class="item.customClass"
        >
          <br />
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  computed: {
    ...mapGetters('AuthStore', ['_userInfo']),
    ...mapState('dashboardDataStore', ['results', 'loading']),
    ...mapGetters('dashboardDataStore', ['_partnerInfo', '_isAdmin', '_isPartner']),
    _statisticsItems() {
      const { totalSale, countImage, countImageZodiac, countImagePendingApproval } = this.results

      let _totalSale = ''
      let _partner = this._partnerInfo

      if (this._isPartner || this._userInfo.role == '7fa970cc-aa32-49bc-9cf6-50f996bc6385') {
        _totalSale = `${this.withCommas(totalSale - (_partner.revenue_sharing * totalSale) / 100)} ( ${this.withCommas(
          totalSale,
        )} )`
      } else {
        _totalSale = totalSale
      }
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: _totalSale,
          subtitle: 'ยอดขาย',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ImageIcon',
          color: 'light-success',
          title: this.withCommas(countImage, false),
          subtitle: 'จำนวน รูป',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ChromeIcon',
          color: 'light-success',
          title: this.withCommas(countImageZodiac, false),
          subtitle: 'จำนวน ราศี',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: this.withCommas(countImagePendingApproval, false),
          subtitle: 'รูปรอการตรวจสอบ',
          customClass: '',
        },
      ]
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-success',
          title: '230k',
          subtitle: 'ยอดขาย',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ImageIcon',
          color: 'light-success',
          title: '8.549k',
          subtitle: 'จำนวน รูป',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ChromeIcon',
          color: 'light-success',
          title: '1.423k',
          subtitle: 'จำนวน ราศี',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '$9745',
          subtitle: 'รูปรอการตรวจสอบ',
          customClass: '',
        },
      ],
    }
  },
}
</script>
